export const BottomSheet = ({ content, onClose }) => {
  return (
    <div
      className="z-50 fixed top-0 left-0 size-full bg-[rgba(0,0,0,0.4)]"
      role="presentation"
      onClick={onClose}
    >
      <div className="fixed bottom-0 bg-[#ffffff] w-full max-h-[calc(100%-50px)] rounded-t-[10px] ">
        {content}
      </div>
    </div>
  );
};
