import { ReactComponent as CheckedBox } from 'assets/checked_box.svg';
import { cashNoteHeight } from 'pages/cash-note-page/constants';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { customState, isNewInAppBrowserState, platformState } from 'recoil/atoms/campaignState';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import { TabSwitcherUnAuthorized } from '../cash-note-page/tab-switcher-unauthorized';

export function UnauthorizedPage({ isViewType, isCashNote }) {
  const isCashNoteViewType = isViewType && isCashNote;
  const location = useLocation();
  const navigate = useNavigate();

  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);
  const isNewInAppBrowser = useRecoilValue(isNewInAppBrowserState);
  // agreement 파라미터의 값을 변경합니다.

  useEffect(() => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.onOfferwallLoadSuccess();
    sendMessageToSDK('onOfferwallLoadSuccess', null, platform);
  }, []);

  const [isChecked, setIsChecked] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showUnauthorizedCashnotePage, setShowUnauthorizedCashnotePage] = useState(false);

  const onClickAgree = () => {
    // 기존의 쿼리 파라미터를 가져옵니다.

    const queryParams = new URLSearchParams(location.search + location.hash);

    // agreement 파라미터의 값을 변경합니다.
    queryParams.set('agreement', 'true');
    const newSearch = queryParams.toString();

    // 변경된 쿼리 파라미터가 포함된 새로운 URL을 생성합니다.
    const newURL = location.pathname + '?' + newSearch;

    // 히스토리 스택에 새 항목을 추가하고, 백키를 눌러도 이전 페이지로 돌아가지 않도록 합니다.
    navigate(newURL, { replace: true });

    if (!isChecked) return;
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.onAgreePrivacy();
    sendMessageToSDK('onAgreePrivacy', null, platform);
  };

  const onClickDisAgree = () => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.onDisagreePrivacy(true);

    sendMessageToSDK('onDisagreePrivacy', { callback: true }, platform);
    setShowAlert(!showAlert);
  };

  const onClickClose = () => {
    // if (!window) return;
    // if (!window?.APRewardOfferwall) return;

    // window?.APRewardOfferwall?.closeOfferwall();

    if (isViewType) {
      setShowAlert(false);
      return setShowUnauthorizedCashnotePage(true);
    }

    if (isNewInAppBrowser) {
      return sendMessageToSDK('closeNewInAppBrowser', null, platform);
    }

    sendMessageToSDK('closeOfferwall', null, platform);
  };

  if (showUnauthorizedCashnotePage) {
    return <TabSwitcherUnAuthorized />;
  }

  return (
    <div
      className="w-full bg-[rgba(0,0,0,0.5)] z-20 absolute top-0 left-0 overflow-hidden px-[30px] flex justify-center items-center"
      style={{ height: isCashNote && isViewType ? `${cashNoteHeight}px` : '100vh' }}
    >
      {!showAlert && (
        <div className="w-[420px] rounded-[14px] bg-white">
          <div className="text-gray-900 text-[18px] leading-[27px] py-[16px] flex justify-center border-b border-[#F1F2F4]">
            개인정보 수집 동의
          </div>
          <div className="p-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
            <div>
              애드팝콘은 광고 참여 정보 확인 및 리워드 제공을 위하여 아래의 정보를 수집 및 이용하고
              있습니다.
            </div>
            <div>
              <div>수집하는 개인정보의 항목</div>
              <div className="text-gray-900">Google 광고 ID</div>
            </div>
            <div>
              <div>개인정보 수집 동의 거부</div>
              <div className="text-gray-900">
                개인 정보 수집 동의를 거부할 수 있으며, 미 동의 시 서비스 이용이 제한됩니다.
              </div>
            </div>
            <div className="flex py-[14px] gap-[8px]">
              {/* <img
                className="object-contain"
                width={20}
                height={20}
                src={`${isChecked ? checkedBox : checkBox}`}
                alt="체크박스"
                role="presentation"
                onClick={() => setIsChecked(!isChecked)}
              /> */}
              <div className="w-[20px] h-[20px]">
                <CheckedBox
                  onClick={() => setIsChecked(!isChecked)}
                  width={20}
                  height={20}
                  fill={isChecked ? custom.primaryColor : '#D9D9D9'}
                />
              </div>
              <div className="text-[15px] leading-[21px] text-gray-900">
                개인정보 이용 및 수집에 동의합니다.
              </div>
            </div>
            <div className="flex gap-[10px]">
              <button
                className="flex justify-center items-center w-[50%] h-[46px] rounded-[8px] bg-[#F1F2F4] text-[#9CA6AF]"
                onClick={onClickDisAgree}
              >
                거부
              </button>
              <button
                onClick={onClickAgree}
                disabled={!isChecked}
                style={{
                  backgroundColor: isChecked ? custom.primaryColor : '#B8BFC6',
                }}
                className={`flex justify-center items-center w-[50%] h-[46px] rounded-[8px] text-white`}
              >
                동의
              </button>
            </div>
          </div>
        </div>
      )}
      {showAlert && (
        <div className="w-[300px] rounded-[14px] bg-white p-[16px]">
          <div className="text-gray-900 text-[18px] leading-[27px] flex justify-center">알림</div>
          <div className="pt-[8px] pb-[16px] text-[14px] text-gray-600 leading-[21px] flex flex-col gap-[12px]">
            개인정보 이용 및 수집에 동의하셔야 애드팝콘 이용이 가능합니다.
          </div>
          <button
            style={{
              backgroundColor: custom.primaryColor,
            }}
            className="w-full flex justify-center items-center h-[46px] rounded-[8px] text-white"
            onClick={onClickClose}
          >
            확인
          </button>
        </div>
      )}
    </div>
  );
}
