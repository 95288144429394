// import { RVAd } from 'components/RV-ad';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';

import Header from 'components/header';
import TabSwitcher from 'components/tab-switcher';
import {
  cashNoteState,
  customState,
  isLandscapeState,
  isViewModeState,
  mediaKeyState,
  platformState,
} from '../../recoil/atoms/campaignState';
import { CashNotePage } from '../cash-note-page';
import { UnauthorizedPage } from '../unauthorized-page';

export function MainPage() {
  const location = useLocation();

  const [mediaKey, setMediaKey] = useRecoilState(mediaKeyState);
  const [platform, setPlatform] = useRecoilState(platformState);
  const [custom, setCustom] = useRecoilState(customState);
  const [cashNote, setCashNote] = useRecoilState(cashNoteState);
  const [isViewMode, setIsViewMode] = useRecoilState(isViewModeState);
  const [isLandscape, setIsLandscape] = useRecoilState(isLandscapeState);

  const queryParams = new URLSearchParams(location.search + location.hash);

  const Agreement = queryParams.get('agreement');
  const Platform = queryParams.get('platform');
  const mediakey = queryParams.get('mediakey');

  const primaryColor = queryParams.get('primaryColor');
  const startTabIndex = queryParams.get('startTabIndex');
  const offerwallTitle = queryParams.get('offerwallTitle');
  const isViewType = queryParams.get('isViewType') === 'true';
  const isLandScape = queryParams.get('isLandscape') === 'true';

  const isAgreed = Agreement === 'true';

  useEffect(() => {
    if (!mediakey) return;

    if (parseInt(mediakey) === 42430018 || parseInt(mediakey) === 901924092) {
      setCashNote(true);
    }
    // if (parseInt(mediakey) === 860337341 || parseInt(mediakey) === 74876820) {
    //   return setCustom((prev) => {
    //     return {
    //       offerwallTitle: offerwallTitle || prev.offerwallTitle,
    //       primaryColor: '#FA6EE3',
    //       startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
    //     };
    //   });
    // }

    setIsLandscape(isLandScape);
    setCustom((prev) => {
      return {
        offerwallTitle: offerwallTitle || prev.offerwallTitle,
        primaryColor: primaryColor || prev.primaryColor,
        startTabIndex: startTabIndex ? parseInt(startTabIndex) : prev.startTabIndex,
      };
    });
  }, []);

  useEffect(() => {
    if (!Platform) return;
    setPlatform(Platform || 'android');
    sendMessageToSDK('getParameter', null, Platform || 'android');
  }, [Platform]);

  useEffect(() => {
    if (mediakey) {
      setMediaKey(parseInt(mediakey));
    }
  }, [mediakey]);

  useEffect(() => {
    setIsViewMode(isViewType);
  }, [isViewType]);

  if (!isAgreed) return <UnauthorizedPage isViewType={isViewType} isCashNote={cashNote} />;
  if (!platform) return null;

  // if (mediaKey === 860337341 || mediaKey === 74876820) return <CustomPage />;

  if (cashNote && isViewType) return <CashNotePage />;

  return (
    <div className="bg-white h-full w-full overflow-hidden flex flex-col touch-none">
      <Header mediakey={mediaKey} />
      <TabSwitcher />
    </div>
  );
}

export default MainPage;
