export const CustomCampaignJoinButton = ({ joinCampaign }) => {
  return (
    <div className="flex justify-center py-20">
      <div className="flex justify-center items-center my-20 text-white fixed bottom-0 w-[calc(100%-40px)]">
        <div
          className="rounded-[16px] flex justify-center items-center text-white font-semibold bg-[#2D91FF] px-24 w-full h-[56px]"
          role="presentation"
          onClick={joinCampaign}
        >
          참여하고 적립받기
        </div>
      </div>
    </div>
  );
};
