import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import {
  isNewInAppBrowserState,
  isViewModeState,
  parameterState,
  platformState,
} from '../recoil/atoms/campaignState';

const useModal = () => {
  const [modalContent, setModalContent] = useState(null);
  const platform = useRecoilValue(platformState);
  const isViewMode = useRecoilValue(isViewModeState);
  const isNewInAppBrowser = useRecoilValue(isNewInAppBrowserState);
  const [parameter, setParameter] = useRecoilState(parameterState);

  const handleNativeEvent = (eventDetail) => {
    const resultMessage = (eventDetail?.ResultMessage || '')
      .replace(/<br\s*\/?>/gi, '\n') // <br> 또는 <br/> 태그를 \n으로 변환
      .replace(/<\/br>/gi, '\n'); // </br> 태그를 \n으로 변환

    switch (eventDetail.EventName) {
      case 'rewardSuccess':
        setModalContent({
          ResultMessage: resultMessage,
          center: { text: '닫기', func: closeModal },
        });
        break;
      case 'normalDialog':
        setModalContent({
          ResultMessage: resultMessage,
          center: { text: '닫기', func: closeModal },
        });
        break;

      case 'executeAppAndReward':
        setModalContent({
          ResultMessage: resultMessage,
          center: {
            text: '확인',
            func: () => {
              sendMessageToSDK('executeAppAndReward', null, platform);
              closeModal();
            },
          },
        });
        break;
      default:
        // closeModal();
        break;
    }
  };

  useEffect(() => {
    const nativeResponse = (event) => {
      const eventData = event?.detail?.data;
      if (!eventData) return;

      if (eventData.EventName === 'getParameter') {
        setParameter(JSON.parse(eventData.EventBody));
      }

      if (eventData.EventName === 'refreshCampaignList') {
        window.location.reload();
      }
      handleNativeEvent(eventData);
    };

    window.addEventListener('NativeEvent', nativeResponse);

    return () => {
      window.removeEventListener('NativeEvent', nativeResponse);
      setModalContent(null);
    };
  }, []);

  const openModal = (errorMsg, cb) => {
    setModalContent({
      ResultMessage: errorMsg,
      center: {
        text: '닫기',
        func: () => {
          if (cb && typeof cb === 'function') {
            return cb();
          }
          if (!window) return;

          if (isViewMode) {
            return closeModal();
          }

          if (isNewInAppBrowser) {
            return sendMessageToSDK('closeNewInAppBrowser', null, platform);
          }

          sendMessageToSDK('closeOfferwall', null, platform);
        },
      },
    });
  };

  const closeModal = () => {
    // setIsModalOpen(false);
    setModalContent(null);
  };

  return { modalContent, openModal, closeModal };
};

export default useModal;
