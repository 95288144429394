import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { platformState } from 'recoil/atoms/campaignState';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import Modal from '../components/modal';

export function Footer() {
  const [showModal, setShowModal] = useState(false);
  const platform = useRecoilValue(platformState);
  const modalContent = {
    ResultMessage: '애드팝콘 홈페이지로 이동 하시겠습니까?',
    left: { text: '닫기', func: () => setShowModal(false) },
    right: {
      text: '이동',
      func: () => window.open('https://reward.adpopcorn.com/', '_blank'),
    },
  };

  return (
    <div className="mx-[-15px] h-[38px]">
      <div className="bg-[#F8F9FA] py-[12px] px-[15px] flex justify-between text-[12px] text-[#9CA6AF] leading-[14.32px]">
        <span role="presentation" onClick={() => setShowModal(true)}>
          Adpopcorn All Rights Reserved
        </span>
        <span
          role="presentation"
          onClick={(e) => {
            sendMessageToSDK(
              'openWebBrowser',
              { url: 'https://reward.adpopcorn.com/policy/privacy' },
              platform
            );
          }}
        >
          개인정보처리방침
        </span>
      </div>
      {showModal && <Modal onClose={() => setShowModal(false)} modalContent={modalContent} />}
    </div>
  );
}
